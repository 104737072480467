import React from "react";
import "../assets/styles/SaferServicesSection.css";
import service1 from "../assets/image/service1.jpg";
import service2 from "../assets/image/service2.jpg";
import service3 from "../assets/image/service3.jpg";
import service4 from "../assets/image/service4.jpg";
import service5 from "../assets/image/service5.jpg";
import service6 from "../assets/image/service6.jpg";

const SaferServicesSection = () => {
  const serviceData = [
    {
      title: "Fire Suppression",
      description:
        "Vestibulum placerat fermentum orci morbi nisl. Congue fringilla est torquent penatibus nullam eget vivamus.",
      image: service1,
      alt: "Fire Suppression",
    },
    {
      title: "Rescue Services",
      description:
        "Mattis imperdiet augue lobortis porta maximus sed bibendum neque cubilia.",
      image: service2,
      alt: "Rescue Services",
    },
    {
      title: "Medical Services",
      description:
        "Mattis imperdiet augue lobortis porta maximus sed bibendum neque cubilia.",
      image: service3,
      alt: "Medical Services",
    },
    {
      title: "Community Safety",
      description:
        "Mattis imperdiet augue lobortis porta maximus sed bibendum neque cubilia.",
      image: service4,
      alt: "Community Safety",
    },
    {
      title: "Fire Investigation",
      description:
        "Mattis imperdiet augue lobortis porta maximus sed bibendum neque cubilia.",
      image: service5,
      alt: "Fire Investigation",
    },
    {
      title: "Ambulance Services",
      description:
        "Mattis imperdiet augue lobortis porta maximus sed bibendum neque cubilia.",
      image: service6,
      alt: "Ambulance Services",
    },
  ];

  return (
    <section className="parallax-section">
      <div className="parallax-hero">
        <h2>service</h2>
      </div>
      <div className="floating-cards">
        {serviceData.map((service, index) => (
          <div className="floating-card" key={index}>
            <div className="floating-card-image-wrapper">
              <img
                src={service.image}
                alt={service.alt}
                className="floating-card-image"
              />
            </div>
            <div className="floating-card-content">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <button className="floating-card-button">Learn More</button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SaferServicesSection;
