import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import SaferServicesSection from "./components/SaferServicesSection";
import Product from "./pages/Product";
import AboveFooter from "./components/AboveFooter";
import CheckOut from "./pages/CheckOut";
import Cart from "./pages/Cart";

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/services" element={<SaferServicesSection />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/CheckOut" element={<CheckOut />} />
        <Route path="/cart" element={<Cart />} />
      </Routes>
      <AboveFooter />
      {/* <Footer /> */}
    </Router>
  );
};

export default App;
