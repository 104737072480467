import React from "react";
import "../assets/styles/HeroSection.css";
import "../assets/styles/EmergencySection.css";
import "../assets/styles/FirefighterSection.css";
import "../assets/styles/SaferServicesSection.css";
import "../assets/styles/Team.css";
import HeroSection from "../components/HeroSection";
import SaferServicesSection from "../components/SaferServicesSection";
import Team from "../components/Team";
import EmergencySection from "../components/EmergencySection";
import FirefighterSection from "../components/FirefighterSection";
import mainImage from "../assets/image/mainimage.jpg";
import sideimage from "../assets/image/sidephoto.jpg";
import whychoose from "../assets/image/whychoose.jpg";
import service1 from "../assets/image/service1.jpg";
import service2 from "../assets/image/service2.jpg";
import service3 from "../assets/image/service3.jpg";
import service4 from "../assets/image/service4.jpg";
import service5 from "../assets/image/service5.jpg";
import service6 from "../assets/image/service6.jpg";
import avatar from "../assets/image/avatar.jpg";

const Home = () => {
  const teamMembers = [
    {
      name: "Simonol",
      role: "Fire Fighter",
      img: "https://via.placeholder.com/300x400?text=Fire+Fighter+1",
    },
    {
      name: "Frank Mir",
      role: "Fire Fighter",
      img: "https://via.placeholder.com/300x400?text=Fire+Fighter+2",
    },
    {
      name: "Jackson",
      role: "Fire Fighter",
      img: "https://via.placeholder.com/300x400?text=Fire+Fighter+3",
    },
  ];
  const serviceData = [
    {
      title: "Fire Suppression",
      description:
        "Vestibulum placerat fermentum orci morbi nisl. Congue fringilla est torquent penatibus nullam eget vivamus.",
      image: service1,
      alt: "Fire Suppression",
    },
    {
      title: "Rescue Services",
      description:
        "Mattis imperdiet augue lobortis porta maximus sed bibendum neque cubilia.",
      image: service2,
      alt: "Rescue Services",
    },
    {
      title: "Medical Services",
      description:
        "Mattis imperdiet augue lobortis porta maximus sed bibendum neque cubilia.",
      image: service3,
      alt: "Medical Services",
    },
    {
      title: "Community Safety",
      description:
        "Mattis imperdiet augue lobortis porta maximus sed bibendum neque cubilia.",
      image: service4,
      alt: "Community Safety",
    },
    {
      title: "Fire Investigation",
      description:
        "Mattis imperdiet augue lobortis porta maximus sed bibendum neque cubilia.",
      image: service5,
      alt: "Fire Investigation",
    },
    {
      title: "Ambulance Services",
      description:
        "Mattis imperdiet augue lobortis porta maximus sed bibendum neque cubilia.",
      image: service6,
      alt: "Ambulance Services",
    },
  ];
  return (
    <div>
      <section className="hero">
        <h1>Welcome to Fire & Safety</h1>
        <p>Protecting Lives and Property with Expert Fire Safety Solutions</p>
        <button>Learn More</button>
      </section>
      <div className="emp-emergency-section">
        <div className="emp-image-container">
          <img src={mainImage} alt="Firefighter" className="emp-main-image" />
          <div className="emp-overlay-image-container">
            <img
              src={sideimage}
              alt="Firefighter Gear"
              className="emp-overlay-image"
            />
          </div>
        </div>
        <div className="emp-text-container">
          <h2>WHO WE ARE</h2>
          <h3 className="emp-section-title">
            Responding to the call of duty with unwavering dedication
          </h3>
          <p className="emp-section-description">
            ROSAL SAFETY PVT LTD is a leading provider of design, supply,
            installation, testing, and commissioning of fire & safety systems.
            Rosal Safety commit to deliver high quality of fire extinguishers
            and other fire safety products.
          </p>
          <p className="emp-section-description">
            With a team of highly skilled professionals, we ensure that our
            clients receive topquality solutions tailored to their specific
            needs. Our services also include annual maintenance of fire systems
            to ensure optimal performance and compliance with safety
            regulations. We understand the importance of fire safety and strive
            to provide reliable, efficient, and cost-effective solutions.
            Committing to integrity & independence and accountability add value
            to clients as an objective source of advice and assurance.
          </p>
          <div className="emp-features">
            <div className="emp-feature">
              <span className="emp-feature-icon">✔️</span>
              <div>
                <h4>Rapid Emergency Response</h4>
                <p>Enim ipsum sociosqu quisque sem molestie potenti.</p>
              </div>
            </div>
            <div className="emp-feature">
              <span className="emp-feature-icon">✔️</span>
              <div>
                <h4>Prevention and Education</h4>
                <p>Sem tristique suscipit duis magnis maximus.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="new-unique-container">
        <div className="new-unique-content">
          <h4 className="new-unique-why-choose-us">Why Choose Us</h4>
          <h2 className="new-unique-title">
            Our strength endures even when the smoke clears
          </h2>
          <p className="new-unique-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>
          <div className="new-unique-features">
            <div className="new-unique-feature">
              <div className="new-unique-icon">
                <i className="fas fa-users"></i>
              </div>
              <div className="new-unique-feature-text">
                <h3>Fast Response Team</h3>
                <p>
                  Nullam curabitur morbi mauris fringilla sagittis lorem.
                  Ultrices velit mus litora vivamus leo lorem curae placerat
                  mattis diam.
                </p>
              </div>
            </div>
            <div className="new-unique-feature">
              <div className="new-unique-icon">
                <i className="fas fa-fire"></i>
              </div>
              <div className="new-unique-feature-text">
                <h3>Experienced Firefighter</h3>
                <p>
                  Nullam curabitur morbi mauris fringilla sagittis lorem.
                  Ultrices velit mus litora vivamus leo lorem curae placerat
                  mattis diam.
                </p>
              </div>
            </div>
            <div className="new-unique-feature">
              <div className="new-unique-icon">
                <i className="fas fa-clock"></i>
              </div>
              <div className="new-unique-feature-text">
                <h3>Ready 24 Hours</h3>
                <p>
                  Nullam curabitur morbi mauris fringilla sagittis lorem.
                  Ultrices velit mus litora vivamus leo lorem curae placerat
                  mattis diam.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="new-unique-image-section">
          <div className="new-unique-experience">
            <span>50+</span>
            <p>Years Experience</p>
          </div>
          <img src={whychoose} alt="Firefighter" className="new-unique-image" />
        </div>
      </div>
      {/* Updated Our Value Section */}
      <section className="our-value-section">
        <div className="our-value-container">
          <div className="our-value-left">
            <h4>OUR VALUE</h4>
            <h2>Putting others' safety before our own</h2>
          </div>
          <div className="our-value-right">
            <div className="value-card">
              <h3>Our Vision</h3>
              <p>
                A safer world through innovation, education, and excellence in
                fire protection and safety solutions.
              </p>
            </div>
            <div className="value-card">
              <h3>Our Mission</h3>
              <p>
                Protecting lives, property, and the environment through top-tier
                fire safety services, continuous improvement, and a culture of
                safety driven by education, training, and innovation.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="parallax-section">
        <div className="parallax-hero">
          <h2>service</h2>
        </div>
        <div className="floating-cards">
          {serviceData.map((service, index) => (
            <div className="floating-card" key={index}>
              <div className="floating-card-image-wrapper">
                <img
                  src={service.image}
                  alt={service.alt}
                  className="floating-card-image"
                />
              </div>
              <div className="floating-card-content">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <button className="floating-card-button">Learn More</button>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="team-section">
        <h2 className="team-title">FireFighters Team</h2>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-card">
              <div className="image-container">
                <img src={avatar} alt={member.name} className="team-image" />
                <div className="hover-overlay">
                  <div className="icon-container">
                    <a href="#facebook" className="social-icon fb">
                      f
                    </a>
                    <a href="#twitter" className="social-icon tw">
                      x
                    </a>
                    <a href="#google" className="social-icon gp">
                      G+
                    </a>
                  </div>
                </div>
              </div>
              <div className="team-info">
                <h3 className="team-name">{member.name}</h3>
                <p className="team-role">{member.role}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
